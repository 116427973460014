.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 130px auto 0;
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #6d5dff;
    border-radius: 2px;
    padding: 14px 20px;
  }
  &__welcome {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & span {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }

    & button {
      background: #1a1c2b;
      border-style: solid;
      border-image: linear-gradient(to right, #4fb0ff31, #6d5dff3b) 1;
      border-radius: 10px;
      margin-left: 400px;
      color: #fff;
      font-weight: 500;
    }
  }
  &__link-wrap {
    display: flex;
    flex-direction: row;
  }
  &__link {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  & a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    padding: 30px 34px;
    min-width: 200px;
    margin: 16px;
    background: #26273b;
    border-radius: 8px;
    letter-spacing: 0.7px;
    text-align: center;
    &:hover {
      color: #503dfa;
    }
  }
}
