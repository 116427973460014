body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  background: #1a1c2b;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0 auto;
  color: #d1d2d9;
}
main {
  margin: auto;
  width: 100%;
  //max-width: 960px;
}
